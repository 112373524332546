/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
import { getBokkingModel } from './bookingFormModel';
import { useForm} from '../../form';
import { Button, ErrorMessage, usePost } from '../../components';
import env from '../../env';
import {useState} from 'react';
//import { useHistory } from "react-router-dom";

export function BookingForm(props){

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'bookingForm', item.cssClass || item.anchorName || '');
    const model = getBokkingModel({});
    const form = useForm(model, { usePlaceholder: true });
    const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
    const post = usePost();
    const [sendingStatus, setSendingStatus] = useState(null);
    //const history = useHistory();

    function onSubmit(e) {
        //console.log("aaaaaa");
        form.validateForm(e, () => {
          const value = { ...form.getValue(), emailReceiver: emailReceiver };
            console.log('value', value);
            post.send(env.apiBase + "/api/contact/sendbookingform", value);
            setSendingStatus('pending');
        }, (errors) => {});
    }

    if (post.done() && sendingStatus === 'pending') {
        //var value = form.getValue();
        //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
        form.reset(model);
        setSendingStatus(null);
        // setTimeout(() => {
        //     history.push('/thank-you');
        //   }, 1000);
    }

    const render = (name) => form.renderControl(name, null);

    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                cmsOption.isCmsEdit ?
                <pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
                    <b> { 'Booking Form'} </b>
                </pre> :
                <form className="contactForm" style={{ maxWidth:'960px' }}>
                    <div className="form-row">
                        <div className="col-sm-4 col-12 mb-2">
                            <label>Name</label>
                            {render('name')}
                        </div>
                        <div className="col-sm-4 col-12 mb-2">
                            <label>Phone Number</label>
                            {render('phone')}
                        </div>
                        <div className="col-sm-4 col-12 mb-2">
                            <label>Email</label>
                            {render('email')}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-sm-4 col-12 mb-2">
                            <label>Vehicle Type</label>
                            {render('vehicleType')}
                        </div>
                        <div className="col-sm-4 col-12 mb-2">
                            <label> Number of Passengers </label>
                            {render('numofPassengers')}
                        </div>
                        <div className="col-sm-4 col-12 mb-2">
                            <label>Trailer Required</label>
                            {render('trailerRequired')}
                        </div> 
                    </div>
                
                    <div className="form-row">
                        <div className="col-sm-6 col-12 mb-2">
                            <label> Date of travel</label>
                            {render('dateofTravel')}
                        </div>

                        <div className="col-sm-6 col-12 mb-2">
                            <label> Time of travel</label>
                            <div className="row">
                                <div className="col-sm-4 col-12 mb-2">
                                    {render('timeofTravelHour')}
                                </div>
                                <div className="col-sm-4 col-12 mb-2">
                                    {render('timeofTravelMin')}
                                </div>
                                <div className="col-sm-4 col-12 mb-2">
                                    {render('timeofTravelAmorPm')}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="col-sm-6 col-12 mb-2">
                            <label> Pick up address</label>
                            {render('pickUpAddress')}
                        </div>
                        <div className="col-sm-6 col-12 mb-2">
                            <label> Drop off address </label>
                            {render('dropoffAddress')}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-12 col-sm-12 mb-2"> 
                            <label> Special Requests </label>
                            {render('enquiry')}
                        </div>
                    </div>

                    <div className="actions">
                        <Button onClick={onSubmit} status={post.status}> SEND ENQUIRY </Button>
                        <ErrorMessage errors={post.errors} />
                        {/* {post.status === 'done' && 'Thank you for your enquiry, we will endeavour to reply to you as promptly as possible'} */}
                        {
                            post.status === 'done' &&
                            <div style={{marginTop:'0.5rem'}}>
                                Thank you for your enquiry, we will endeavour to reply to you as promptly as possible
                            </div>
                        }        
                    </div>

                </form>
            }
        </div>
    )
}