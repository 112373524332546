import { fb, validators } from '../../lib/form';

export function getBokkingModel(data) {
  
    const vehicleTypeList = [
        {name : 'Executive Sedan', value : 'Executive Sedan'},
        {name : 'European Luxury Sedan', value : 'European Luxury Sedan'},
        {name : 'Luxury SUV', value : 'Luxury SUV'},
        {name : 'Luxury People Movers', value : 'Luxury People Movers'},
        {name : 'Minibus', value : 'Minibus'}
    ];

    const trailerRequiredList = [
        {name : 'No', value : 'No'},
        {name : 'Yes', value : 'Yes'}
    ];

    const hourList = [
        {name : 'Hour', value : ''},
        {name : '01', value : '01'},
        {name : '02', value : '02'},
        {name : '03', value : '03'},
        {name : '04', value : '04'},
        {name : '05', value : '05'},
        {name : '06', value : '06'},
        {name : '07', value : '07'},
        {name : '08', value : '08'},
        {name : '09', value : '09'},
        {name : '10', value : '10'},
        {name : '11', value : '11'},
        {name : '12', value : '12'},
    ];

    const MinList = [
        {name : 'Min', value : ''},
        {name : '00', value : '00'},
        {name : '05', value : '05'},
        {name : '10', value : '10'},
        {name : '15', value : '15'},
        {name : '20', value : '20'},
        {name : '25', value : '25'},
        {name : '30', value : '30'},
        {name : '35', value : '35'},
        {name : '40', value : '40'},
        {name : '45', value : '45'},
        {name : '50', value : '50'},
        {name : '55', value : '55'},
    ];

    const AmPmList = [
        {name : 'AM / PM', value : ''},
        {name : 'AM', value : 'AM'},
        {name : 'PM', value : 'PM'}
    ];

    const minDate = new Date( new Date().getTime() + 2*24*60*60*1000 );
    const minDateFormatted = minDate.getFullYear() + '-' + 
        ((''+(minDate.getMonth() + 1)).length < 2 ? '0' + (minDate.getMonth() + 1) : (minDate.getMonth() + 1)) + '-' +
        ((''+minDate.getDate()).length < 2 ? '0' +  minDate.getDate() : minDate.getDate());

    //console.log('minDateFormatted', minDateFormatted);
    
    const model = fb.group({
        name : [data.name || '', [validators.Required()], { label:null, type: 'text' }],
        phone : [data.phone || '', [validators.Required('Phone number is required')], { label: null, type: 'text' }],
        email : [data.email || '', [validators.Required('2.	Email address is required'), validators.Email()], { label: null, type: 'email' }],
        vehicleType : [data.vehicleType || vehicleTypeList[0].value, [validators.Required()], { label: null, type: 'select', options: vehicleTypeList }],
        trailerRequired : [data.trailerRequired || trailerRequiredList[0].value, [validators.Required()], { label: null, type: 'select', options: trailerRequiredList }],
        numofPassengers : [data.numofPassengers || '', [validators.Required('Number of passengers is required')], { label: null, type: 'number' }],
        dateofTravel : [data.dateofTravel || '', [validators.Required('Date of travel is required')], { label: null, type: 'date', attrs: { min: minDateFormatted } }],
        
        timeofTravelHour : [data.timeofTravelHour || '', [validators.Required('Hour is required')], { label: null, type: 'select', options: hourList }],
        timeofTravelMin : [data.timeofTravelMin || '', [validators.Required('Min is required')], { label: null, type: 'select', options : MinList }],
        timeofTravelAmorPm : [data.timeofTravelAmorPm || '', [validators.Required('AM / PM is required')], { label: null, type: 'select', options : AmPmList }],
        
        pickUpAddress : [data.pickUpAddress || '', [validators.Required('Pick up address is required')], { label: null, type: 'text' }],
        dropoffAddress : [data.dropoffAddress || '', [validators.Required('Drop off address is required')], { label: null, type: 'text' }], 
        enquiry: [data.enquiry || '', [], { label: null, type: 'textarea' }],
    }); 
  return model;
}