import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    height: [60, null, 75],
    //height: [44, null, 56],
    background: '#231f20',
    //background:'transparent',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    //background:'white'
    height: [44, null, 56],
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [52, null, 65],
    //marginTop:'1.5rem',
    transition:'all 0.2s ease-in-out'
  })),
  logoScrolled: css(mq({
    'img' : css(mq({
      height: [38, null, 50],
      marginTop:'0px'
    }))
  }))
}